<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ProductServiceApi from "@/service/api/product";
import CategoriesServiceApi from "@/service/api/categories";

/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Product Detail",
      productId: this.$route.params.id,
      productData: null,
      items: [
        {
          text: "Ecommerce",
        },
        {
          text: "Product Detail",
          active: true,
        },
      ],
      genderOpt: {
        M: "Men",
        F: "Women",
        C: "Children",
        P: " Preschool",
        I: "Toddler",
        T: "Infant",
      },
      productForm: {
        product_id: null,
        name: null,
        desc: null,
        color: null,
        SKU: null,
        condition: null,
        retail_price: null,
        release_date: null,
        image_path: null,
        gender: null,
        grade: null,
        secondary_id: null,
      },
      myFiles: [],
      // mainCatSelect: null,
      primaryCatSelect: null,
      primaryCategory: null,
      secondaryCategory: null,
      categories: null,
    };
  },
  methods: {
    submitProductHandler(data) {
      var form_data = new FormData();
      form_data.append("color", data.color);
      form_data.append("condition", data.condition);
      form_data.append("desc", data.desc);
      form_data.append("gender", data.gender);
      if (
        this.productForm.image_path &&
        this.productForm.image_path.files &&
        this.productForm.image_path.files[0]
      ) {
        form_data.append(
          "image_path",
          this.productForm.image_path.files[0].file
        );
      }
      form_data.append("product_id", data.product_id);
      form_data.append("name", data.name);
      form_data.append("release_date", data.release_date);
      form_data.append("retail_price", data.retail_price);
      form_data.append("secondary_id", data.secondary_id);
      form_data.append("SKU", data.SKU);

      form_data.append("action", "edit");

      ProductServiceApi.productAction(form_data).then((response) => {
        if (response.data.success) {
          this.productUpdateMessage(response.data.product[0]);
        } else {
          this.productUpdateErrorMessage(response.data.error);
        }
        this.loadProduct();
      });
    },
    filterPrimaryCategory(id = null) {
      if (id) {
        let primeData = this.categories.filter(
          (item) => item.id === parseInt(id)
        );
        if (primeData.length > 0) {
          return primeData[0].primary;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    resetForm() {
      this.setForm(this.productData);
    },
    productUpdateMessage(data, variant = "success") {
      this.$bvToast.toast(`Product ${data.name} is updated !`, {
        title: `Product update`,
        variant: variant,
        solid: true,
      });
    },
    productUpdateErrorMessage(data, variant = "danger") {
      this.$bvToast.toast(`Product is not updated!, failed with error`, {
        title: `Opration Failed`,
        variant: variant,
        solid: true,
      });
    },
    setForm(val) {
      // this.mainCatSelect = val.category_id;
      this.primaryCatSelect = val.primaryCategory_id;
      this.productForm = {
        product_id: val.id,
        name: val.name,
        desc: val.desc,
        color: val.color,
        SKU: val.SKU,
        condition: val.condition,
        retail_price: val.retail_price,
        release_date: this.moment(new Date(val.release_date)).format(
          "YYYY-MM-DD"
        ),
        image_path: val.image_path,
        gender: val.gender,
        grade: val.grade,
        secondary_id: val.secondaryCategory_id,
      };
    },

    filterSecondaryCategory(id = null) {
      if (id && this.primaryCategory) {
        let secondData = this.primaryCategory.filter(
          (item) => item.id === parseInt(id)
        );

        if (secondData.length > 0) {
          const data = secondData[0].secondary.map((item) => {
            return {
              label: `${item.name}`,
              value: item.id,
            };
          });
          this.secondaryCategory = data;
          return data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    async loadProduct() {
      await ProductServiceApi.getProductData(this.productId).then(
        (response) => {
          response.data.data.forEach(e => {
            if(e.id == this.productId){
              this.productData = e;
            }
          });
          console.log('=-=-=-=-=-=-LOADING PRODUCT IN PRODUCT DETAIL=-=-=-=-=-=-=-=-=-');
          console.log(this.productData);
          console.log('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
        }
      );
    },
  },
  mounted() {
    this.loadProduct();
    CategoriesServiceApi.getAllCategories().then((response) => {
      this.categories = response.data;
    });
  },
  watch: {
    productData(val) {
      this.setForm(val);
    },
    // mainCatSelect(val) {
    //   if (val) {
    //     this.primaryCategory = this.filterPrimaryCategory(val);
    //   }
    // },
    primaryCatSelect(val) {
      this.secondaryCategory = this.filterSecondaryCategory(val);
    },
  },
  computed: {
    imageUrl() {
      return this.productData.image;
    },
    productSizes() {
      var size = [];
      this.productData.sizes.map((item) => {
        size.push(item.size);
      });
      return size;
    },
    mainCategory() {
      return this.categories.map((item) => {
        return {
          text: `${item.name_en}-${item.name_ar}`,
          value: item.id,
        };
      });
    },
    primaryCategoryOpt() {
      if (this.primaryCategory) {
        return this.primaryCategory.map((item) => {
          return {
            text: `${item.name}`,
            value: item.id,
          };
        });
      } else {
        return [];
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" v-if="productData">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-5 col-md-6">
                <div class="product-detail">
                  <div class="row">
                    <div class="col-12">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="product">
                          <div class="product-img">
                            <img
                              id="productImg"
                              :src="productData.coverimage"
                              alt
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-lg-7 col-md-6">
                <div class="mt-4 mt-xl-3">
                  <h5 class="mt-1 mb-3">{{ productData.name_en }}</h5>

                  <div>
                    Description (English): {{ productData.description_en }}
                  </div>
                  <div>
                    Description (Arabic): {{ productData.description_ar }}
                  </div>
                  <!-- <p class="mt-3">Color : {{ productData.color }}</p> -->
                </div>
              </div>
            </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
    <!-- </div> -->
    <!-- end row -->
  </Layout>
</template>

<style>
.text-arabic {
  display: block;
}
.product-tag-st {
  margin: 30px 0px;
}

.product-tag-st .custom-control-inline {
  width: 60px;
}
</style>
