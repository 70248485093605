import axios from "axios";
import authHeader from '../auth-header'
import ConfigService from "../config";
// import authHeaderMultipart from "../auth-header-multipart"
const API_URL = ConfigService.base_url;

class CategoriesServiceApi {
  getAllMainCategories(only = true) {
    console.log(only)
    return axios
      .get(API_URL + "admin/category", {
        header: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getAllCategories() {
    return axios
      .get(API_URL + "admin/category")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  // categoryAction(category){
  //   return axios
  //   .post(API_URL + 'category',category,
  //   {
  //     headers: authHeaderMultipart()
  //   })
  //   .then(response => {
  //     return response;
  //   })
  //   .catch(error=> {
  //       return Promise.reject(error);
  //     }
  //   )
  // }
  getPrimaryCategories() {
    return axios
      .get(API_URL + "admin/category", {
        headers:authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  deleteCategory(id) {
    return axios
      .delete(API_URL + `admin/category/${id}`,{
        headers:authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
    async primaryCategoryAction(data){
    return await  axios
    .post(API_URL + 'admin/category',data,
    {
      headers: authHeader()
    });
  }
  getSecondaryCategories() {
    return axios
      .get(API_URL + "secondary-categories", {
        params: {
          action: "admin",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  secondaryCategoryAction(data){
    return axios
    .post(API_URL + 'secondary-category',data,
    {
      headers: authHeader()
    })
    .then(response => {
      return response;
    })
    .catch(error=> {
        return Promise.reject(error);
      }
    )
  }
}

export default new CategoriesServiceApi();
