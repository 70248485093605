export default function authHeaderMultipart() {
  let token = JSON.parse(localStorage.getItem('token'))
  if (token) {
      return { 
          Authorization: 'Bearer ' +  token,
        'Content-Type': 'multipart/form-data',
       }
      
  } else {
    return {
    
    }
  }
}
