import axios from "axios";
// import authHeader from '../auth-header'
import ConfigService from "../config";
import authHeaderMultipart from "../auth-header-multipart"
import authHeader from "../auth-header"
const API_URL = ConfigService.base_url;

class ProductServiceApi {
  getAllProducts(page) {
    return axios
      .get(API_URL + "admin/product", {
        params:{ page: page},
        headers:authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  productAction(product){
    return axios
    .post(API_URL + 'admin/product', product,
    {
      headers: authHeaderMultipart()
    })
    .then(response => {
      return response;
    })
    .catch(error=> {
        return Promise.reject(error);
      }
    )
  }
  getProductData(id){
    return axios
    .get(API_URL + 'admin/product', 
    {
      params:{ product_id: id},
      headers: authHeader()
    })
    .then(response => {
      return response;
    })
    .catch(error=> {
        return Promise.reject(error);
      }
    )
  }
}

export default new ProductServiceApi();
